<template>
  <div class="user">
    <div class="user__avatar"></div>
    <div class="user__info">
      <div class="user__name">{{user.given_name}}  {{user.family_name}}</div>
      <div class="user__settings" v-if="usersCanManage([userRoles.COMPANY_ADMIN])" @click="$router.push({name: 'Settings'})">
        Inställningar
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {userRolesTypes} from "@/utils/user-roles";

export default {
  data() {
    return {
      userRoles: userRolesTypes
    }
  },
  computed: {
    ...mapGetters([
      'getUserRoles'
    ]),
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    usersCanManage(roles) {
      return this.getUserRoles.some(item => roles.includes(item))
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  @include flexbox;

  &__avatar {
    height: 57px;
    width: 57px;
    border-radius: 10px;
    background: url('~@/assets/images/user-avatar.png');
    background-size: cover;
    border: 3px solid #ffffff;
    @include box-shadow(0, 4px, 10px, 0, rgb(0 0 0 / 15%));
  }

  &__info {
    padding-left: 10px;
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
  }

  &__name {
    font-weight: 700;
    font-size: 16px;
  }

  &__settings {
    margin-top: 5px;
    color: var(--color-gray);
    font-size: 13px;
    cursor: pointer;
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

<template>
  <div class="container-fluid dashboard">
    <div class="d-flex">
      <app-menu></app-menu>
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import AppMenu from "@/components/core/AppMenu";
export default {
  components: {AppMenu}
}
</script>

<style lang="scss" scoped>
.dashboard {
  padding-top: 20px;
  height: 100%;

  .main {
    padding-left: 300px;
    width: 100%;
    padding-bottom: 50px;
  }
}
</style>

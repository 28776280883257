<template>
  <div class="app-menu">
    <app-menu-user></app-menu-user>
    <div class="mt-5 d-flex flex-column">
      <div class="flex-grow-1 app-menu__list">
        <app-menu-item route="overview" name="Översikt" v-if="usersCanManage([userRoles.COMPANY_ADMIN, userRoles.SIMPLE_USER])"><mileage-icon/></app-menu-item>
        <app-menu-item route="driveLog" name="Körjournal" v-if="usersCanManage([userRoles.COMPANY_ADMIN, userRoles.SIMPLE_USER])"><drive-log/></app-menu-item>
        <app-menu-item name="Karta" route="map" v-if="usersCanManage([userRoles.COMPANY_ADMIN, userRoles.SIMPLE_USER])"><map-icon/></app-menu-item>
        <app-menu-item name="Företag" v-if="usersCanManage([userRoles.SUPER_ADMIN, userRoles.RESELLER_ADMIN])" route="Companies"><company-icon/></app-menu-item>
        <app-menu-item route="boxes" v-if="usersCanManage([userRoles.SUPER_ADMIN, userRoles.RESELLER_ADMIN, userRoles.COMPANY_ADMIN])" name="Enheter"><cube-icon/></app-menu-item>
        <app-menu-item name="Fordon" v-if="usersCanManage([userRoles.SUPER_ADMIN, userRoles.RESELLER_ADMIN, userRoles.COMPANY_ADMIN])" route="Vehicles"><vehicle-icon/></app-menu-item>
        <app-menu-item name="Användare" v-if="usersCanManage([userRoles.SUPER_ADMIN, userRoles.RESELLER_ADMIN, userRoles.COMPANY_ADMIN])" route="Users"><user-icon/></app-menu-item>
<!--        <app-menu-item name="Tjänster"><settings-icon id="settingsIcon"/></app-menu-item>-->
      </div>
      <app-menu-item @click.native="logout" name="Logga ut"><logout-icon id="Logout"/></app-menu-item>
    </div>
  </div>
</template>

<script>
import AppMenuUser from "@/components/core/AppMenuUser";
import AppMenuItem from "@/components/core/AppMenuItem";

import MileageIcon from '@/assets/icons/speedometer-outline.svg?inline';
import DriveLog from '@/assets/icons/albums-outline.svg?inline';
import CubeIcon from '@/assets/icons/cube-outline.svg?inline';
import UserIcon from '@/assets/icons/person-add-outline.svg?inline';
import CompanyIcon from '@/assets/icons/building.svg?inline';
import MapIcon from '@/assets/icons/map-outline.svg?inline';
import VehicleIcon from '@/assets/icons/car-outline.svg?inline';
import SettingsIcon from '@/assets/icons/wrench-outline.svg?inline';
import LogoutIcon from '@/assets/icons/log-out-outline.svg?inline'
import {mapGetters} from "vuex";
import {userRolesTypes} from "@/utils/user-roles";

export default {
  components: {
    AppMenuItem,
    AppMenuUser,
    MileageIcon,
    DriveLog,
    CubeIcon,
    UserIcon,
    MapIcon,
    VehicleIcon,
    SettingsIcon,
    LogoutIcon,
    CompanyIcon
  },
  data() {
    return {
      userRoles: userRolesTypes
    }
  },
  computed: {
    ...mapGetters([
        'getUserRoles'
    ]),
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push('/login');
    },
    usersCanManage(roles) {
      return this.getUserRoles.some(item => roles.includes(item))
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.app-menu {
  padding-left: 40px;
  height: 100%;
  width: 280px;
  position: fixed;

  &__list {
    height: calc(100vh - 200px);
  }
}
</style>

<template>
  <div class="menu-item" @click="goToRoute">
      <div class="menu-item__icon" :class="{'active': active}">
        <slot></slot>
      </div>
      <div class="menu-item__name">{{name}}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    route: String
  },
  computed: {
    active() {
      return this.$route.name === this.route;
    }
  },
  methods: {
    goToRoute() {
      if(!this.route || this.active) return;

      this.$router.push({name: this.route});
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-item {
  @include flexbox;
  @include align-items(center);
  cursor: pointer;
  margin-bottom: 15px;

  &__icon {
    width: 50px;
    margin-right: 20px;
    padding: 14px 25px 13px 0;

    path[fill] {
      fill: var(--color-gray-blueish)
    }

    path[stroke] {
      stroke: var(--color-gray-blueish);
    }

    .path {
      fill: var(--color-gray-blueish)
    }

  }

  .active {
    background: var(--color-secondary);
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    position: relative;

    path[fill] {
      fill: #ffffff;
    }

    path[stroke] {
      stroke: #ffffff;
    }

    .path {
      fill: #ffffff;
    }


    &:before {
      content: "";
      height: 100%;
      position: absolute;
      left: 1px;
      top: 0;
      background: var(--color-secondary);
      width: 55px;
      transform: translateX(-55px);
    }
  }

  &__name {
    font-weight: 600;
    font-size: 14px;
    height: 23px;
    color: var(--color-gray-blueish);

    &:hover {
      color: var(--color-secondary);
    }
  }
}
</style>
